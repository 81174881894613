import { ReactElement } from 'react';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { IonCheckbox } from '@ionic/react';

const ConsentCheckbox = ({ consentValue, setConsentValue }): ReactElement => {
    const { t } = useTranslation();

  return (
    <CheckBox>
    <CheckBoxLabel>{t("home_content_check_text")}</CheckBoxLabel>
    <IonCheckbox
      style={{}}
      checked={consentValue}
      onIonChange={(e) => setConsentValue(e.detail.checked)}
    />
  </CheckBox>

  );
};

const CheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  border-radius : 15px;
  height : 5rem;
  background-color : ${props => props.theme.secondary.main};
`;


const CheckBoxLabel = styled.div`
margin-right: max(5px, auto);
flex: 1;
text-decoration : underline;
font-weight: bold;
`;

export default ConsentCheckbox;